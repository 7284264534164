import React from 'react';
import './WhatOurClientsSay.scss';
import PropTypes from 'prop-types';
import QuoteIcon from './images/QuoteIcon.svg';
import OurClients from './OurClients';

const Testimonial = ({ client }) => (
  <div className="testimonial" key={client.name}>
    <h2 className="client-name">{client.name}</h2>
    <h3 className="client-role">{client.role}</h3>
    <img src={QuoteIcon} alt="Quote Icon" className="quote-icon" />
    <div className="quote-container">
      <p className="quote">
        {client.quote}
      </p>
      <a className="clutchLink" href={client.clutchLink} target="_blank" rel="noopener noreferrer"> Read the Clutch reviews</a>
    </div>
  </div>
);

const clients = [
  {
    name: 'Jay Evans',
    role: 'E-Commerce Manager, Tiege Hanley',
    quote: 'Their attention to detail and passion are there — it’s a perfect game all around.',
    clutchLink: 'https://clutch.co/profile/build-1#review-1658718',
  },
  {
    name: 'Emmiliese Von Clemm',
    role: 'Product Manager, MyVillage',
    quote: 'What makes working with Build so great is their investment in the users\' needs and the success of the business.',
    clutchLink: 'https://clutch.co/profile/build-1#review-1768227',
  },
  {
    name: 'Aaste Palczewski',
    role: 'General Partner, My Shipping Post',
    quote: 'Their team feels like part of our organization.',
    clutchLink: 'https://clutch.co/profile/build-1#review-1670873',
  },
];

const WhatOurClientsSay = () => (
  <div className="testimonial-container">
    <h2 className="section-subtitle bc--primary">What Our Clients Say</h2>
    <div className="testimonials-grid">
      {clients.map((client) => <Testimonial key={`${client.name}_${client.role}`} client={client} />)}
    </div>
    <OurClients />
  </div>
);

Testimonial.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    quote: PropTypes.string,
    clutchLink: PropTypes.string,
  }).isRequired,
};

export default WhatOurClientsSay;
