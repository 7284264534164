import React, { useEffect, useState } from 'react';
import './ClientSlide.scss';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useWindowSize from '../../../hooks/useWindowSize';
import breakpoints from '../../../constants/breakpoints';

const ClientSlide = ({ clientInfo }) => {
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();
  useEffect(() => {
    if (size.width < breakpoints.lg) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size.width]);

  if (!clientInfo.id) {
    return <></>;
  }

  const logo = getImage((clientInfo.logo || {}).asset);
  const topLeftCorner = getImage((clientInfo.topLeftCorner || {}).asset);
  const topRightCorner = getImage((clientInfo.topRightCorner || {}).asset);
  const bottomRightCorner = getImage((clientInfo.bottomRightCorner || {}).asset);
  const bottomLeftCorner = getImage((clientInfo.bottomLeftCorner || {}).asset);
  const asset = getImage((clientInfo.asset || {}).asset);
  const mainImage = getImage((clientInfo.image || {}).asset);
  const backgroundImage = getImage((clientInfo.background || {}).asset);

  return (
    <div
      rel="noopener noreferrer"
      className="client-container"
      key={clientInfo.name}
      style={(backgroundImage && !isMobile) ? { backgroundImage: `url(${backgroundImage.images.fallback.src})` } : {}}
    >
      <div className="client-content">
        {topLeftCorner && !isMobile && <GatsbyImage image={topLeftCorner} className="grid-image top-left" alt={`${clientInfo.name}-Top-Left`} />}
        {topRightCorner && !isMobile && <GatsbyImage image={topRightCorner} className="grid-image top-right" alt={`${clientInfo.name}-Top-Right`} />}
        {bottomLeftCorner && !isMobile && <GatsbyImage image={bottomLeftCorner} className="grid-image bottom-left" alt={`${clientInfo.name}-Bottom-Left`} />}
        {bottomRightCorner && !isMobile && <GatsbyImage image={bottomRightCorner} className="grid-image bottom-right" alt={`${clientInfo.name}-Bottom-Right`} />}
        {asset && !isMobile && <GatsbyImage image={asset} className="client-asset" alt={`${clientInfo.name}-Asset`} />}

        <div className="left-content">
          <GatsbyImage image={logo} className="client-logo" alt={clientInfo.name} />
          <h1 className="client-name">{ clientInfo.name }</h1>
          <p className="client-description"> { clientInfo.description }</p>
          { clientInfo.caseStudyLink ? <a className="case-study-button" href="/" style={{ backgroundColor: clientInfo.caseStudyColor }}>See Case Study</a> : ''}
        </div>
        <div className="right-content">
          <div className="image-container">
            <GatsbyImage
              image={mainImage}
              key={`${clientInfo.name}main`}
              title={`${clientInfo.name}main`}
              alt={`${clientInfo.name}main`}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

ClientSlide.propTypes = {
  clientInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    caseStudyLink: PropTypes.string,
    caseStudyColor: PropTypes.any,
    background: PropTypes.object,
    logo: PropTypes.object,
    image: PropTypes.object,
    asset: PropTypes.object,
    topLeftCorner: PropTypes.object,
    topRightCorner: PropTypes.object,
    bottomLeftCorner: PropTypes.object,
    bottomRightCorner: PropTypes.object,
  }),
};
ClientSlide.defaultProps = {
  clientInfo: {
    id: '',
    name: '',
    description: '',
    caseStudyLink: '',
    caseStudyColor: '',
    background: {},
    logo: {},
    image: {},
    asset: {},
    topLeftCorner: {},
    topRightCorner: {},
    bottomLeftCorner: {},
    bottomRightCorner: {},
  },
};

export default ClientSlide;
