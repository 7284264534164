import React from 'react';
import './Awards.scss';
import b2bAward from '../../../images/B2B-award.png';
import b2bAward2023 from '../../../images/B2B-award_2023.png';
import clutch2021 from '../../../images/clutch_2021.png';
import clutch2022 from '../../../images/clutch_2022.png';

const Awards = () => (
  <div className="awards">
    <h2 className="awards__header">
      A Top Performing B2B Provider in Costa Rica
    </h2>
    <div className="images-container">
      <img
        className="awards__img"
        src={b2bAward}
        alt="Clutch award 2021"
      />
      <img
        className="awards__img"
        src={b2bAward2023}
        alt="Clutch award 2023"
      />
      <img
        className="awards__img"
        src={clutch2021}
        alt="Clutch award 2021"
      />
      <img
        className="awards__img"
        src={clutch2022}
        alt="Clutch award 2022"
      />
    </div>
  </div>
);

export default Awards;
