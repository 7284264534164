import Slider from 'react-slick';
import React from 'react';
import './OurWork.scss';
import { graphql, useStaticQuery } from 'gatsby';
import ClientSlide from './ClientSlide';

const query = graphql`
query {
  allSanityClientsSlider(sort: {fields: order, order: ASC}, filter: {enabled: {eq: true}}) {
    edges {
      node {
        id
        name
        description
        caseStudyLink
        caseStudyColor
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        logo {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        background {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        asset {
          asset {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        topLeftCorner {
          asset {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        topRightCorner {
          asset {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        bottomLeftCorner {
          asset {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        bottomRightCorner {
          asset {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`;

const OurWork = () => {
  const allClients = useStaticQuery(query);
  const clients = allClients.allSanityClientsSlider.edges.map((client) => client.node);
  return (
    <div className="d-flex flex-column our-work-container">
      <Slider
        dots
        infinite
        speed={500}
        autoplaySpeed={5000}
        arrows={false}
        autoplay
      >
        { clients.map((client) => <ClientSlide key={client.id} clientInfo={client} />)}
      </Slider>

    </div>
  );
};

export default OurWork;
