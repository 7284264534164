import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import lottie from 'lottie-web';
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import mainHeroAnimation from '../../../animations/components/heroAnimation';
import './HomeHero.scss';

const HomeHero = () => {
  const dataHeroImages = useStaticQuery(
    graphql`{
  heroBg: file(relativePath: {eq: "hero-background.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  const mainAnimationContainer = React.createRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: mainAnimationContainer.current,
      animationData: mainHeroAnimation,
    });
  }, []);

  return (
    <Container
      className="home-hero-container d-flex flex-column position-relative align-items-center p-0"
      fluid
    >
      <GatsbyImage
        image={dataHeroImages.heroBg.childImageSharp.gatsbyImageData}
        className="background"
        alt="Home background"
      />
      <div className="d-flex text-and-image-container">
        <div className="hero-text-container d-flex flex-column mt-1 mt-md-5">
          <div className="d-flex flex-column titles-container">
            <div className="hero-title fw-bold">
              <p className="me-0">Build Products.</p>
            </div>
            <div className="hero-subtitle mt-0 mt-sm-1 mt-md-2">
              <p className="me-0 mb-0">
                Our team of innovative and forward-thinking individuals are
                dedicated to building alongside you to transform your software
                development dream into a profitable business.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex hero-animation-container">
          <div className="d-flex hero-animation">
            <div
              className="d-flex animation"
              ref={mainAnimationContainer}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeHero;
