/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Player from 'react-player/vimeo';
import videoPoster from '../../../images/videoposter.jpeg';
import './MeetBuild.scss';

const VIDEO_SRC = process.env.GATSBY_MEET_BUILD_VIDEO_SRC;
const MeetBuild = () => {
  const PlayButton = (
    <button type="button" className="meet-build__play-btn" alt="play video" />
  );

  return (
    <div className="meet-build d-flex justify-content-center flex-column">
      <h2 className="section-subtitle bc--primary meet-build__title">Why Build?</h2>
      <div className="meet-build__video-container">
        <Player
          url={VIDEO_SRC}
          playing
          className="meet-build__video"
          width="100%"
          height="100%"
          light={videoPoster}
          controls
          playIcon={PlayButton}
        />
      </div>
    </div>
  );
};

export default MeetBuild;
