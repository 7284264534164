import React from 'react';
import './OurExpertise.scss';
import DedicatedTeams from './images/DedicatedTeams.svg';
import UserCentered from './images/User-Centered.svg';
import QualityDelivery from './images/QualityDelivery.svg';
import Partnership from './images/Partnership.svg';

const OurExpertise = () => {
  const listOfExpertise = [
    {
      title: 'Dedicated Teams',
      description: 'We deliver web or mobile application development, architecting of cloud platforms, instantiation of security and compliance programs, DevOps enablement, and hardening of development processes.',
      image: <img src={DedicatedTeams} alt="Build" className="build-logo" />,
    },
    {
      title: 'User-Centered Products',
      description: 'Through extensive brainstorming, planning, and user feedback, we build seamless interfaces that bring to life your product vision and provide everything your users need.',
      image: <img src={UserCentered} alt="Build" className="build-logo" />,
    },
    {
      title: 'Quality Delivery',
      description: 'Build is dedicated to perfection, through meticulous work and attention to detail, we build the highest quality of products. We also understand that your customers are your top priority, we\'re here to ensure you have all the tools you need to make their experience a great one.',
      image: <img src={QualityDelivery} alt="Build" className="build-logo" />,
    },
    {
      title: 'Partnership',
      description: 'As a business built on relationships and expertise, we\'re not here to just do business, we\'re here to join you as your product team. Whatever bumps in the road may come, we\'ll be there to put our heads together with you and plan the best steps forward for you and your business.',
      image: <img src={Partnership} alt="Build" className="build-logo" />,
    },
  ];
  return (
    <div className="d-flex flex-column our-expertise-container">
      <div className="our-expertise-grids">
        <div className="expertise-card top-card">
          <h2> Our Expertise </h2>
          <p> This is what your new product team can do for you</p>
        </div>
        {
          listOfExpertise.map((expertise) => (
            <div key={expertise.title} className="expertise-card">
              { expertise.image }
              <h2> { expertise.title } </h2>
              <p> { expertise.description } </p>
            </div>
          ))
        }
        <div className="expertise-card bottom-card">
          <h2>Ready for your next project?</h2>
          <p>Let’s build it, together.</p>
          <a href="/contact-us/#contact-form"> Contact Us </a>
        </div>

      </div>

    </div>
  );
};

export default OurExpertise;
