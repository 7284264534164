import PropTypes from 'prop-types';
import React from 'react';
import { GetInTouch } from '../components/Common';
import {
  Awards,
  HomeHero,
  MeetBuild,
  OurExpertise,
  OurWork,
  WhatOurClientsSay,
} from '../components/Home';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Index = ({ location }) => (
  <>
    <SEO title="Build" description="Welcome to Build" location={location} />
    <Layout location={location}>
      <HomeHero />
      <OurWork />
      <OurExpertise />
      <MeetBuild />
      <WhatOurClientsSay />
      <Awards />
      <GetInTouch />
    </Layout>
  </>
);

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Index;
