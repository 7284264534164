import React from 'react';
import Ahana from './images/logos/Ahana.png';
import AutismScreen from './images/logos/AutismScreen.png';
import Avanlee from './images/logos/Avanlee.png';
import DugalDiabetes from './images/logos/DugalDiabetes.png';
import FanUp from './images/logos/FanUp.png';
import MSP from './images/logos/MSP.png';
import MyVillage from './images/logos/MyVillage.png';
import Opio from './images/logos/Opio.png';
import PintPass from './images/logos/PintPass.png';
import PZ from './images/logos/PZ.png';
import Tiege from './images/logos/Tiege.png';
import ViralForecast from './images/logos/ViralForecast.png';
import ZPPR from './images/logos/ZPPR.png';

import './OurClients.scss';

const OurClients = () => (
  <div className="our-clients">
    <h2 className="heading text-start"> Our Clients </h2>
    <div className="our-clients-first-row">
      <img src={Tiege} alt="Tiege" className="main-logo tiege" />
      <img src={MyVillage} alt="MyVillage" className="main-logo my-village" />
      <img src={MSP} alt="MyShippingPost" className="main-logo msp" />
      <img src={PZ} alt="PriorityZones" className="main-logo pz" />
      <img src={ZPPR} alt="ZPPR" className="main-logo zppr" />
    </div>
    <div className="our-clients-second-row">
      <img src={Opio} alt="Opio" className="secondary-logo opio" />
      <img src={ViralForecast} alt="ViralForecast" className="secondary-logo viral-forecast" />
      <img src={AutismScreen} alt="AutismScreen" className="secondary-logo autism-screen" />
      <img src={Avanlee} alt="AutismScreen" className="secondary-logo avanlee" />
      <img src={Ahana} alt="Ahana" className="secondary-logo ahana" />
      <img src={DugalDiabetes} alt="DugalDiabetes" className="secondary-logo dugal-diabetes" />
      <img src={FanUp} alt="FanUp" className="secondary-logo fan-up" />
      <img src={PintPass} alt="PintPass" className="secondary-logo pint-pass" />
    </div>
  </div>
);

export default OurClients;
